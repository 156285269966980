import * as userActions from "./user.action";
import * as themeActions from "./theme.action";
import * as modalActions from "./modal.action";
import * as videoActions from "./video.action";
import * as uploadActions from "./upload.action";
import * as resumeActions from "./resume.action";
import * as pointsActions from "./points.action";
import * as networkActions from "./network.action";
import * as videoBarActions from "./videobar.action";
import * as uploadCourse from "./course-upload.action";
import * as uploadBundle from "./bundle-upload.action";
import * as locPointsActions from "./loc_points.action";
import * as enrollmentActions from "./enrollment.action";
import * as userDetailActions from "./userDetails.action";
import * as videoMetaDataActions from "./video_meta_data.action";
import * as videoPreferenceActions from "./video_preference.action";
import * as entityActions from "./entity.action";
import * as tourActions from "./tour.action";

const actions = {
  userActions,
  themeActions,
  uploadCourse,
  uploadBundle,
  modalActions,
  videoActions,
  uploadActions,
  resumeActions,
  pointsActions,
  networkActions,
  videoBarActions,
  locPointsActions,
  enrollmentActions,
  userDetailActions,
  videoMetaDataActions,
  videoPreferenceActions,
  entityActions,
  tourActions,
};

export default actions;
