import actions from "@/store/actions";

export const addVideoMetaData = (data) => {
  return async (dispatch) => {
    dispatch(actions.videoMetaDataActions.addMetaData(data));
  };
};

export const addCurrVideo = (data) => {
  return async (dispatch) => {
    dispatch(actions.videoMetaDataActions.addCurrVideo(data));
  };
};

export const resetVideoMetaData = () => {
  return async (dispatch) => {
    dispatch(actions.videoMetaDataActions.resetMetaData());
  };
};

export const updateVideoMetaData = (data) => {
  return async (dispatch) => {
    dispatch(actions.videoMetaDataActions.updateMetaData(data));
  };
};

export const addBulkVideoMetaData = (data) => {
  return async (dispatch) => {
    dispatch(actions.videoMetaDataActions.addBulkMetaData(data));
  };
};
