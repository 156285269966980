//VIDEOS
export const UPDATE_IDS = "updateids";
export const UPDATE_TEST_INFO = "updatetestinfo";
export const UPDATE_VIDEO_INFO = "updatevideoinfo";
export const UPDATE_PLAY_RATE = "update_play_rate";
export const UPDATE_COURSE_INFO = "updatecourseinfo";
export const UPDATE_ARTICLE_INFO = "updatearticleinfo";
export const UPDATE_ENROLLMENT_INFO = "updateenrollmentstatus";
export const TOGGLE_MOBILE_BOTTOM_BAR = "toggle_mobile_bottom_bar";
export const UPDATE_WATCH_LATER_STATUS = "updatewatchlaterstatus";
