import actions from "@/store/actions";

export const toggleBar = () => {
  return async (dispatch) => {
    dispatch(actions.videoBarActions.bar());
  };
};
// export const toggleHybridContent = () => {
//   return async (dispatch) => {
//     dispatch(actions.videoBarActions.hybrid());
//   };
// };
export const toggleNextContent = () => {
  return async (dispatch) => {
    dispatch(actions.videoBarActions.nextcontent());
  };
};
export const toggleCommentContent = () => {
  return async (dispatch) => {
    dispatch(actions.videoBarActions.comments());
  };
};
export const toggleNotes = () => {
  return async (dispatch) => {
    dispatch(actions.videoBarActions.notes());
  };
};
export const toggleAssignmentContent = () => {
  return async (dispatch) => {
    dispatch(actions.videoBarActions.assignment());
  };
};
