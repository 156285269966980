import types from "../types";

export const updateids = (info) => ({
  type: types.videoTypes.UPDATE_IDS,
  payload: info,
});

export const updatevideoinfo = (info) => ({
  type: types.videoTypes.UPDATE_VIDEO_INFO,
  payload: info,
});

export const updatearticleinfo = (info) => ({
  type: types.videoTypes.UPDATE_ARTICLE_INFO,
  payload: info,
});

export const updatetestinfo = (info) => ({
  type: types.videoTypes.UPDATE_TEST_INFO,
  payload: info,
});

export const updatecourseinfo = (courseInfo) => ({
  type: types.videoTypes.UPDATE_COURSE_INFO,
  payload: courseInfo,
});

export const updateenrollmentstatus = (enrollmentInfo) => ({
  type: types.videoTypes.UPDATE_ENROLLMENT_INFO,
  payload: enrollmentInfo,
});

export const update_play_rate = (playrate) => ({
  type: types.videoTypes.UPDATE_PLAY_RATE,
  payload: playrate,
});

export const toggle_mobile_bottom_bar = (status) => ({
  type: types.videoTypes.TOGGLE_MOBILE_BOTTOM_BAR,
  payload: status,
});

export const toggleWishlistStatus = (status) => ({
  type: types.videoTypes.UPDATE_WATCH_LATER_STATUS,
  payload: status,
});
