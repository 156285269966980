import { toggleTheme } from "./theme.service";
import { fetchUser, logoutUser } from "./user.service";
import {
  playerInitialize,
  updateVideoPreference,
} from "./videoPreference.service";
import { fetchNetwork, followUser, unfollowUser } from "./network.service";
import {
  addCurrVideo,
  addVideoMetaData,
  resetVideoMetaData,
  updateVideoMetaData,
  addBulkVideoMetaData,
} from "./videoMetaData.service";

import {
  fetchEnrollment,
  enrollUser,
  unEnrollUser,
  // addProgress,
} from "./enrollment.service";

import {
  fetchUserDetails,
  updateUserProfileProgress,
  resetUserDetails,
} from "./userDetails.service";

import { addEntity, resetEntity } from "./entity.service";
import {
  startNewVideoPageTour,
  stopNewVideoPageTour,
  startCertificateTour,
  stopCertificateTour,
  startPostEnrollTour,
  stopPostEnrollTour,
  startPreEnrollTour,
  stopPreEnrollTour,
  startNotesTour,
  stopNotesTour,
} from "./tour.service";

import {
  toggleSigninModal,
  toggleAlertModal,
  toggleAssignmentUploadModal,
  toggleCertificateModal,
  toggleCreatorModal,
  toggleMentorModal,
  toggleFeedbackModal,
  toggleInterestModal,
  toggleLinkUploadModal,
  toggleProfileModal,
  togglePointsModal,
  togglePasswordModal,
  toggleUploadVideoModal,
  toggleStageModal,
  toggleEditAboutModal,
  toggleExperienceModal,
  toggleAddEducationModal,
  toggleAddSkillModal,
  toggleAddAwardsModal,
  toggleAddCertificateModal,
  toggleAddContactModal,
  toggleAddProjectsModal,
  toggleRequestRecommendationModal,
  toggleViewContactModal,
  toggleAlumniModal,
  toggleRedeemModal,
  updateVideoModal,
  toggleIntroModal,
  toggleResourcesModal,
  toggleConfirmDetailsModal,
  togglePolicyModal,
  togglePublishCourseModal,
  toggleDeleteStageModal,
  toggleDeleteContentModal,
  toggleReviewModal,
  toggleTestimonialModal,
  toggleDeleteUserModal,
  toggleTestLinkModal,
  toogleVideoPageModal,
  tooglePremiumCourseModal,
  toggleVideoWarningModal,
} from "./modal.service";

import {
  successAlert,
  warningAlert,
  failureAlert,
  loadingAlert,
  infoAlert,
  promiseAlert,
} from "./toast.service";

import {
  updateVideoInfo,
  updateArticleInfo,
  updateTestInfo,
  updateCourseInfo,
  updateEnrollmentStatus,
  updatePlayRate,
  toggleBottomBar,
  toggleWishlistStatus,
} from "./video.service";

import {
  toggleBar,
  toggleNotes,
  toggleNextContent,
  // toggleHybridContent,
  toggleCommentContent,
  toggleAssignmentContent,
} from "./videobar.service";

import { fetchStage, fetchVideo } from "./upload.service";

import { fetchPoints, updateEngagement, updatePoints } from "./points.service";

import { fetchResume, resetResume } from "./resume.service";

import { addCourseDetails } from "./upload-course.service";

import { addBundleDetails } from "./upload-bundle.service";

import { addbundledetails } from "../actions/bundle-upload.action";

const services = {
  fetchUser,
  logoutUser,

  // user details
  fetchUserDetails,
  updateUserProfileProgress,
  resetUserDetails,

  toggleTheme,

  fetchNetwork,
  followUser,
  unfollowUser,

  fetchEnrollment,
  enrollUser,
  unEnrollUser,
  // addProgress,

  toggleSigninModal,
  toggleAlertModal,
  toggleAssignmentUploadModal,
  toggleCertificateModal,
  toggleCreatorModal,
  toggleMentorModal,
  toggleFeedbackModal,
  toggleInterestModal,
  toggleLinkUploadModal,
  toggleProfileModal,
  togglePointsModal,
  togglePasswordModal,
  toggleStageModal,
  toggleUploadVideoModal,
  toggleEditAboutModal,
  toggleExperienceModal,
  toggleAddEducationModal,
  toggleAddSkillModal,
  toggleAddAwardsModal,
  toggleAddCertificateModal,
  toggleAddContactModal,
  toggleAddProjectsModal,
  toggleRequestRecommendationModal,
  toggleViewContactModal,
  toggleAlumniModal,
  toggleRedeemModal,
  updateVideoModal,
  toggleIntroModal,
  togglePolicyModal,
  togglePublishCourseModal,
  toggleReviewModal,
  toggleResourcesModal,
  toggleTestimonialModal,
  toggleConfirmDetailsModal,
  toggleDeleteStageModal,
  toggleDeleteContentModal,
  toggleDeleteUserModal,
  toggleTestLinkModal,
  toogleVideoPageModal,
  tooglePremiumCourseModal,
  toggleVideoWarningModal,

  successAlert,
  warningAlert,
  failureAlert,
  loadingAlert,
  infoAlert,
  promiseAlert,

  updateVideoInfo,
  updateArticleInfo,
  updateTestInfo,
  updateCourseInfo,
  updateEnrollmentStatus,
  updatePlayRate,
  toggleBottomBar,
  toggleWishlistStatus,

  toggleBar,
  toggleNotes,
  toggleNextContent,
  // toggleHybridContent,
  toggleCommentContent,
  toggleAssignmentContent,

  fetchVideo,
  fetchStage,

  fetchPoints,
  updatePoints,
  updateEngagement,

  fetchResume,
  resetResume,

  // video meta data
  addCurrVideo,
  addVideoMetaData,
  resetVideoMetaData,
  updateVideoMetaData,
  addBulkVideoMetaData,

  // video preference
  updateVideoPreference,
  playerInitialize,

  addEntity,
  resetEntity,

  // upload course
  addCourseDetails,

  //upload bundle
  addBundleDetails,

  //upload bundle
  addbundledetails,

  //tour
  startNewVideoPageTour,
  stopNewVideoPageTour,
  startCertificateTour,
  stopCertificateTour,
  startPostEnrollTour,
  stopPostEnrollTour,
  startPreEnrollTour,
  stopPreEnrollTour,
  startNotesTour,
  stopNotesTour,
};

export default services;
