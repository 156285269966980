import actions from "@/store/actions";

export const addEntity = (data) => {
  return async (dispatch) => {
    dispatch(actions.entityActions.addentity(data));
    return;
  };
};

export const resetEntity = () => {
  return async (dispatch) => {
    dispatch(actions.entityActions.resetentity());
    return;
  };
};
