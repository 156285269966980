import types from "../types";

export const startcertificatetour = () => ({
  type: types.tourTypes.CERTIFICATE_TOUR_START,
});

export const stopcertificatetour = () => ({
  type: types.tourTypes.CERTIFICATE_TOUR_STOP,
});

export const startpostenrolltour = () => ({
  type: types.tourTypes.POST_ENROLL_TOUR_START,
});

export const stoppostenrolltour = () => ({
  type: types.tourTypes.POST_ENROLL_TOUR_STOP,
});

export const startpreenrolltour = () => ({
  type: types.tourTypes.PRE_ENROLL_TOUR_START,
});

export const stoppreenrolltour = () => ({
  type: types.tourTypes.PRE_ENROLL_TOUR_STOP,
});

export const startnotestour = () => ({
  type: types.tourTypes.NOTES_TOUR_START,
});

export const stopnotestour = () => ({
  type: types.tourTypes.NOTES_TOUR_STOP,
});

export const startvideopagetour = () => ({
  type: types.tourTypes.NEW_VIDEO_PAGE_TOUR_START,
});

export const stopvideopagetour = () => ({
  type: types.tourTypes.NEW_VIDEO_PAGE_TOUR_STOP,
});
