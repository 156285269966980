import types from "../types";

export const fetchresume = (payload) => ({
  type: types.resumeTypes.FETCH_RESUME_SUCCESS,
  payload: payload,
});

export const resetresume = (payload) => ({
  type: types.resumeTypes.RESET_RESUME_DATA,
  payload: payload,
});
