"use client";

import { createContext } from "react";

export const defaultSocketContextState = {
  socket: undefined,
};

export const SocketReducer = (state, action) => {
  switch (action.type) {
    case "update_socket":
      return { ...state, socket: action.payload };
    default:
      return state;
  }
};

const SocketContext = createContext({
  SocketState: defaultSocketContextState,
  SocketDispatch: () => {},
});

export const SocketContextConsumer = SocketContext.Consumer;
export const SocketContextProvider = SocketContext.Provider;

export default SocketContext;
