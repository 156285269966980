import types from "../types";

export const addbundledetails = (data) => ({
  type: types.UploadBundle.ADD_BUNDLE_DETAILS,
  payload: data,
});

export const addcurriculam = (data) => ({
  type: types.UploadBundle.ADD_CURRICULUM,
  payload: data,
});
