import types from "../types";

export const resetpoints = () => ({
  type: types.locPointsTypes.RESET_LOCAL_POINTS,
  payload: 0,
});

export const updatepoints = (locPoints) => ({
  type: types.locPointsTypes.UPDATE_LOCAL_POINTS,
  payload: locPoints,
});
