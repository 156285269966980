import { socketFollow, socketUnFollow } from "@/socket";
import actions from "@/store/actions";
import utils from "@/utils";
import api from "@/api";
import services from ".";

export const fetchNetwork = (user) => {
  return async (dispatch) => {
    if (utils.getCookie("login_state") || user?.email) {
      const response = await api.getAllNetwork();
      if (response.status === 200) {
        dispatch(actions.networkActions.fetchnetwork(response.data));
        return;
      }
    }
    dispatch(actions.networkActions.fetchnetwork([]));
  };
};

export const followUser = (networkId, userId, socket) => {
  return async (dispatch) => {
    const response = await api.followuser({ networkId });
    if (response.data?.success === 200) {
      socketFollow(
        {
          userId,
          entityId: networkId,
        },
        socket
      );
      dispatch(fetchNetwork());
    } else {
      dispatch(services.failureAlert("Failed to follow user!"));
    }
  };
};

export const unfollowUser = (networkId, userId, socket) => {
  return async (dispatch) => {
    const response = await api.removeFollowing(networkId);
    if (response.data?.success === 200) {
      socketUnFollow(
        {
          userId,
          entityId: networkId,
        },
        socket
      );
      dispatch(fetchNetwork());
    } else {
      dispatch(services.failureAlert("Failed to unfollow user!"));
    }
  };
};
