import types from "../types";

export const signinmodal = () => ({
  type: types.modalTypes.TOGGLE_SIGNIN_MODAL,
});

export const alertmodal = () => ({
  type: types.modalTypes.TOGGLE_ALERT_MODAL,
});

export const certificatemodal = () => ({
  type: types.modalTypes.TOGGLE_CERTIFICATE_MODAL,
});

export const creatormodal = () => ({
  type: types.modalTypes.TOGGLE_CREATOR_MODAL,
});

export const mentormodal = () => ({
  type: types.modalTypes.TOGGLE_MENTOR_MODAL,
});

export const feedbackmodal = () => ({
  type: types.modalTypes.TOGGLE_FEEDBACK_MODAL,
});

export const assignmentmodal = () => ({
  type: types.modalTypes.TOGGLE_CREATE_ASSIGNMENT_MODAL,
});

export const videomodal = () => ({
  type: types.modalTypes.TOGGLE_VIDEO_MODAL,
});

export const upload_link_modal = () => ({
  type: types.modalTypes.TOGGLE_UPLOAD_LINK_MODAL,
});

export const interestmodal = () => ({
  type: types.modalTypes.TOGGLE_INTEREST_MODAL,
});

export const passwordmodal = () => ({
  type: types.modalTypes.TOGGLE_PASSWORD_MODAL,
});

export const profilemodal = () => ({
  type: types.modalTypes.TOGGLE_PROFILE_MODAL,
});

export const filtermodal = () => ({
  type: types.modalTypes.SEARCH_FILTER_MODAL,
});

export const pointsmodal = () => ({
  type: types.modalTypes.POINTS_MODAL,
});

export const stagemodal = () => ({
  type: types.modalTypes.STAGE_MODAL,
});

export const aboutModal = () => ({
  type: types.modalTypes.ADD_ABOUT_MODAL,
});

export const experienceModal = () => ({
  type: types.modalTypes.ADD_EXPERIENCE_MODAL,
});

export const educationModal = () => ({
  type: types.modalTypes.ADD_EDUCATION_MODAL,
});

export const skillModal = () => ({
  type: types.modalTypes.ADD_SKILL_MODAL,
});

export const addCertificateModal = () => ({
  type: types.modalTypes.ADD_CERTIFICATE_MODAL,
});

export const awardModal = () => ({
  type: types.modalTypes.ADD_AWARD_MODAL,
});

export const projectModal = () => ({
  type: types.modalTypes.ADD_PROJECT_MODAL,
});

export const contactModal = () => ({
  type: types.modalTypes.ADD_CONTACT_MODAL,
});

export const requestRecommendationModal = () => ({
  type: types.modalTypes.REQUEST_RECOMMENDATION_MODAL,
});

export const viewContactModal = () => ({
  type: types.modalTypes.VIEW_CONTACT,
});

export const alumnimodal = () => ({
  type: types.modalTypes.ALUMNI_MODAL,
});

export const redeemModal = () => ({
  type: types.modalTypes.REDEEM_MODAL,
});

export const videoUpdateModal = () => ({
  type: types.modalTypes.VIDEO_UPDATE_MODAL,
});

export const introModal = () => ({
  type: types.modalTypes.INTRO_MODAL,
});

export const resourcesModal = () => ({
  type: types.modalTypes.RESOURCES_MODAL,
});

export const confirmDetailsModal = () => ({
  type: types.modalTypes.CONFIRM_DETAILS_MODAL,
});

export const policyModal = () => ({
  type: types.modalTypes.POLICY_MODAL,
});

export const togglePublishCourseModal = () => ({
  type: types.modalTypes.PUBLISH_COURSE_MODAL,
});

export const toggleDeleteStageModal = () => ({
  type: types.modalTypes.DELETE_STAGE_MODAL,
});

export const toggleDeleteContentModal = () => ({
  type: types.modalTypes.DELETE_CONTENT_MODAL,
});

export const toggleTestLinkModal = (val) => ({
  type: types.modalTypes.TEST_LINK_MODAL,
  payload: val,
});

export const toggleVideoPageModal = (val) => ({
  type: types.modalTypes.VIDEO_PAGE_MODAL,
  payload: val,
});

export const reviewModal = () => ({
  type: types.modalTypes.REVIEW_MODAL,
});

export const testimonialModal = () => ({
  type: types.modalTypes.TESTIMONIAL_MODAL,
});

export const deleteUserModal = () => ({
  type: types.modalTypes.DELETE_USER_MODAL,
});

export const progressBarModal = () => ({
  type: types.modalTypes.PROGRESS_BAR_MODAL,
});

export const premiumCourseEnrollModal = () => {
  return {
    type: types.modalTypes.PREMIUM_COURSE_ENROLL,
  };
};

export const toggleVideoWarningModal = (status) => {
  return {
    type: types.modalTypes.VIOLATION_ERROR_MODAL,
    payload: status,
  };
};
