import actions from "@/store/actions";

export const toggleSigninModal = () => {
  return (dispatch) => {
    dispatch(actions.modalActions.signinmodal());
  };
};
export const toggleAlertModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.alertmodal());
  };
};
export const toggleCertificateModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.certificatemodal());
  };
};
export const toggleCreatorModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.creatormodal());
  };
};
export const toggleMentorModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.mentormodal());
  };
};
export const toggleFeedbackModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.feedbackmodal());
  };
};
export const toggleAssignmentUploadModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.assignmentmodal());
  };
};
export const toggleUploadVideoModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.videomodal());
  };
};
export const toggleLinkUploadModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.upload_link_modal());
  };
};
export const toggleInterestModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.interestmodal());
  };
};
export const togglePasswordModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.passwordmodal());
  };
};
export const toggleProfileModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.profilemodal());
  };
};
export const toggleFilterModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.filtermodal());
  };
};
export const togglePointsModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.pointsmodal());
  };
};
export const toggleStageModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.stagemodal());
  };
};
export const toggleEditAboutModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.aboutModal());
  };
};
export const toggleExperienceModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.experienceModal());
  };
};
export const toggleAddEducationModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.educationModal());
  };
};
export const toggleAddSkillModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.skillModal());
  };
};
export const toggleAddCertificateModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.addCertificateModal());
  };
};
export const toggleAddAwardsModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.awardModal());
  };
};
export const toggleAddProjectsModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.projectModal());
  };
};
export const toggleAddContactModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.contactModal());
  };
};
export const toggleRequestRecommendationModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.requestRecommendationModal());
  };
};
export const toggleViewContactModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.viewContactModal());
  };
};

export const toggleAlumniModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.alumnimodal());
  };
};

export const toggleRedeemModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.redeemModal());
  };
};

export const updateVideoModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.videoUpdateModal());
  };
};

export const toggleIntroModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.introModal());
  };
};

export const toggleResourcesModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.resourcesModal());
  };
};

export const toggleConfirmDetailsModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.confirmDetailsModal());
  };
};

export const togglePolicyModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.policyModal());
  };
};

export const togglePublishCourseModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.togglePublishCourseModal());
  };
};

export const toggleDeleteStageModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.toggleDeleteStageModal());
  };
};

export const toggleDeleteContentModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.toggleDeleteContentModal());
  };
};

export const toggleReviewModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.reviewModal());
  };
};

export const toggleTestimonialModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.testimonialModal());
  };
};

export const toggleDeleteUserModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.deleteUserModal());
  };
};

export const toggleTestLinkModal = (val) => {
  return async (dispatch) => {
    dispatch(actions.modalActions.toggleTestLinkModal(val));
  };
};

export const toogleVideoPageModal = (val) => {
  return async (dispatch) => {
    dispatch(actions.modalActions.toggleVideoPageModal(val));
  };
};

export const toggleProgressBarModal = (val) => {
  return async (dispatch) => {
    dispatch(actions.modalActions.progressBarModal(val));
  };
};

export const tooglePremiumCourseModal = () => {
  return async (dispatch) => {
    dispatch(actions.modalActions.premiumCourseEnrollModal());
  };
};

export const toggleVideoWarningModal = (status) => {
  return async (dispatch) => {
    dispatch(actions.modalActions.toggleVideoWarningModal(status));
  };
};
