import actions from "@/store/actions";
import apis from "@/api";

export const addCourseDetails = (data) => {
  return async (dispatch) => {
    if (!data?.id) {
      dispatch(actions.uploadCourse.addcoursedetails(null));
      return;
    } else {
      const res = await apis.getcoursedetailsv2(data?.id);
      if (res?.status === 200) {
        dispatch(actions.uploadCourse.addcoursedetails({ ...res.data.result }));
      } else {
        dispatch(actions.uploadCourse.addcoursedetails(null));
        // window.location.replace("/upload/course");
      }
    }
  };
};
