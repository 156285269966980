import actions from "@/store/actions";

export const updateVideoInfo = (info) => {
  return async (dispatch) => {
    dispatch(actions.videoActions.updatevideoinfo(info));
  };
};

export const updateArticleInfo = (info) => {
  return async (dispatch) => {
    dispatch(actions.videoActions.updatearticleinfo(info));
  };
};

export const updateTestInfo = (info) => {
  return async (dispatch) => {
    dispatch(actions.videoActions.updatetestinfo(info));
  };
};

export const updateCourseInfo = ({ courseInfo, isEnrolled, isRestricted }) => {
  return async (dispatch) => {
    dispatch(
      actions.videoActions.updatecourseinfo({
        courseInfo,
        isEnrolled,
        isRestricted,
      })
    );
  };
};

export const updateEnrollmentStatus = ({ isEnrolled }) => {
  return async (dispatch) => {
    dispatch(
      actions.videoActions.updateenrollmentstatus({
        isEnrolled,
      })
    );
  };
};

export const updatePlayRate = (playrate) => {
  return async (dispatch) => {
    dispatch(actions.videoActions.update_play_rate(playrate));
  };
};

export const toggleBottomBar = (status) => {
  return async (dispatch) => {
    dispatch(actions.videoActions.toggle_mobile_bottom_bar(status));
  };
};

export const toggleWishlistStatus = (status) => {
  return async (dispatch) => {
    dispatch(actions.videoActions.toggleWishlistStatus(status));
  };
};
