import types from "../types";

export const bar = () => ({
  type: types.videoBarTypes.TOGGLE_BAR,
});

export const hybrid = () => ({
  type: types.videoBarTypes.TOGGLE_HYBRID_CONTENT,
});

export const comments = () => ({
  type: types.videoBarTypes.TOGGLE_COMMENTS_CONTENT,
});

export const nextcontent = () => ({
  type: types.videoBarTypes.TOGGLE_NEXT_CONTENT,
});

export const notes = () => ({
  type: types.videoBarTypes.TOGGLE_NOTES,
});

export const assignment = () => ({
  type: types.videoBarTypes.TOGGLE_ASSIGNMENT_CONTENT,
});
