import actions from "@/store/actions";
import utils from "@/utils";

export const startCertificateTour = () => {
  return (dispatch) => {
    utils.addToLocalStorage("certificate-tour", "YES");
    dispatch(actions.tourActions.startcertificatetour());
  };
};
export const stopCertificateTour = () => {
  return (dispatch) => {
    utils.addToLocalStorage("certificate-tour", "NO");
    dispatch(actions.tourActions.stopcertificatetour());
  };
};
export const startPostEnrollTour = () => {
  return async (dispatch) => {
    utils.addToLocalStorage("post-enroll-tour", "YES");
    dispatch(actions.tourActions.startpostenrolltour());
  };
};
export const stopPostEnrollTour = () => {
  return async (dispatch) => {
    utils.removeFromLocalStorage("post-enroll-tour");
    dispatch(actions.tourActions.stoppostenrolltour());
  };
};
export const startPreEnrollTour = () => {
  return async (dispatch) => {
    utils.addToLocalStorage("pre-enroll-tour", "YES");
    dispatch(actions.tourActions.startpreenrolltour());
  };
};
export const stopPreEnrollTour = () => {
  return async (dispatch) => {
    utils.addToLocalStorage("pre-enroll-tour", "NO");
    dispatch(actions.tourActions.stoppreenrolltour());
  };
};
export const startNotesTour = () => {
  return async (dispatch) => {
    utils.addToLocalStorage("notes-tour", "YES");
    dispatch(actions.tourActions.startnotestour());
  };
};
export const stopNotesTour = () => {
  return async (dispatch) => {
    utils.removeFromLocalStorage("notes-tour");
    dispatch(actions.tourActions.stopnotestour());
  };
};

export const startNewVideoPageTour = () => {
  return async (dispatch) => {
    utils.addToLocalStorage("new-video-page-tour", "YES");
    dispatch(actions.tourActions.startvideopagetour());
  };
};
export const stopNewVideoPageTour = () => {
  return async (dispatch) => {
    utils.removeFromLocalStorage("new-video-page-tour");
    dispatch(actions.tourActions.stopvideopagetour());
  };
};
