import actions from "@/store/actions";
import utils from "@/utils";

export const toggleTheme = (theme, change) => {
  return async (dispatch) => {
    if (typeof window !== "undefined") {
      const element = document.getElementById("body");
      if (theme === "light") {
        element.classList.replace("dark", "light");
        utils.setCookies("theme", JSON.stringify({ mode: "light", change }), 3);
        dispatch(actions.themeActions.toggletheme(false));
      } else {
        element.classList.replace("light", "dark");
        utils.setCookies("theme", JSON.stringify({ mode: "dark", change }), 3);
        dispatch(actions.themeActions.toggletheme(true));
      }
    }
  };
};
