import { socketHandleEngagement, socketHandlePoints } from "@/socket";
import actions from "@/store/actions";
import utils from "@/utils";
import api from "@/api";

export const fetchPoints = (user) => {
  return async (dispatch) => {
    if (utils.getCookie("login_state") || user?.email) {
      const res = await api.getWatchPoint(user?._id);
      if (res.status === 200 || res.status === 201) {
        dispatch(actions.pointsActions.fetchpoints(res.data?.result));
        return;
      }
    }
    dispatch(actions.pointsActions.fetchpoints({}));
  };
};

export const updatePoints = (points, timespent, socket, userId) => {
  return async (dispatch) => {
    if (timespent > 0) {
      const newLocPoints = points + timespent;
      if (
        newLocPoints >= Number(process.env.NEXT_PUBLIC_MIN_TIME_TO_UPDATE_CP)
      ) {
        socketHandlePoints(
          {
            userId: userId,
            points: newLocPoints,
          },
          socket
        );
        dispatch(actions.locPointsActions.resetpoints());
        return;
      }
      dispatch(actions.locPointsActions.updatepoints(newLocPoints));
    }
  };
};

export const updateEngagement = (engagement, socket, userId) => {
  return async () => {
    if (engagement > 0) {
      socketHandleEngagement(
        {
          userId: userId,
          points: engagement,
        },
        socket
      );
      return;
    }
  };
};
