import actions from "@/store/actions";

export const fetchVideo = () => {
  return async (dispatch) => {
    dispatch(actions.uploadActions.fetch_video());
  };
};
export const fetchStage = () => {
  return async (dispatch) => {
    dispatch(actions.uploadActions.fetch_stage());
  };
};
