//TOUR
export const CERTIFICATE_TOUR_START = "start-certificate-tour";
export const CERTIFICATE_TOUR_STOP = "stop-certificate-tour";
export const POST_ENROLL_TOUR_START = "start-post-enroll-tour";
export const POST_ENROLL_TOUR_STOP = "stop-post-enroll-tour";
export const PRE_ENROLL_TOUR_START = "start-pre-enroll-tour";
export const PRE_ENROLL_TOUR_STOP = "stop-pre-enroll-tour";
export const NOTES_TOUR_START = "start-notes-tour";
export const NOTES_TOUR_STOP = "stop-notes-tour";
export const NEW_VIDEO_PAGE_TOUR_START = "start-new-video-page-tour";
export const NEW_VIDEO_PAGE_TOUR_STOP = "stop-new-video-page-tour";
