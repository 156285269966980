import { toast } from "react-toastify";

export function successAlert(message) {
  return function () {
    toast.success(message);
  };
}
export function infoAlert(message) {
  return function () {
    toast.info(message);
  };
}
export function warningAlert(message) {
  return function () {
    toast.warn(message);
  };
}
export function failureAlert(message) {
  return function () {
    toast.error(message);
  };
}
export function loadingAlert(message) {
  return function () {
    toast.loading(message);
  };
}

export function promiseAlert(fun, messages, success, fail) {
  return async function () {
    const response = await toast.promise(fun(), {
      pending: messages.pending,
      success: `${messages.success} 👌`,
      error: `${messages.error} 🤯`,
    });
    if (response.status === 200) success();
    else fail?.();
  };
}
