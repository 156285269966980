import actions from "@/store/actions";
import utils from "@/utils";
import api from "@/api";

export const fetchUserDetails = (user) => {
  return async (dispatch) => {
    if (utils.getCookie("login_state") || user?.email) {
      const response = await api.getUserDetails();
      if (response?.status >= 400) {
        dispatch(actions.userActions.fetchfailure(response.data));
        utils.setCookies("login_state", false);
        utils.setCookies("verification", false);
        return;
      } else {
        dispatch(
          actions.userDetailActions.fetchUserDetails(response?.data?.result)
        );
        return;
      }
    }
    dispatch(actions.userDetailActions.resetUserDetails());
  };
};

export const updateUserProfileProgress = (progress) => {
  return async (dispatch) => {
    dispatch(actions.userDetailActions.updateUserProfileProgress(progress));
  };
};

export const resetUserDetails = () => {
  return async (dispatch) => {
    dispatch(actions.userDetailActions.resetUserDetails());
  };
};
