import actions from "@/store/actions";

export const fetchResume = () => {
  return async (dispatch) => {
    dispatch(actions.resumeActions.fetchresume(new Date()));
  };
};

export const resetResume = () => {
  return async (dispatch) => {
    dispatch(actions.resumeActions.resetresume(new Date()));
  };
};
