import types from "../types";

export const fetchuser = () => ({
  type: types.userTypes.FETCH_USER_REQUEST,
  payload: false,
});

export const fetchsuccess = (user) => ({
  type: types.userTypes.FETCH_USER_SUCCESS,
  payload: user,
});

export const fetchfailure = (error) => ({
  type: types.userTypes.FETCH_USER_FAILURE,
  payload: error,
});
