import types from "../types";

export const playerInitialize = (data) => ({
  type: types.videoPreference.PLAYER_INITALIZE,
  payload: data,
});

export const updateVideoPreference = (data) => ({
  type: types.videoPreference.UPDATE_VIDEO_PREFERENCE,
  payload: data,
});
