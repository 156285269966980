//MODAL
export const TOGGLE_SIGNIN_MODAL = "signinmodal";

export const TOGGLE_ALERT_MODAL = "alertmodal";

export const TOGGLE_CERTIFICATE_MODAL = "certificatemodal";

export const TOGGLE_CREATOR_MODAL = "creatormodal";

export const TOGGLE_MENTOR_MODAL = "mentormodal";

export const TOGGLE_FEEDBACK_MODAL = "feedbackmodal";

export const TOGGLE_CREATE_ASSIGNMENT_MODAL = "assignmentmodal";

export const TOGGLE_VIDEO_MODAL = "videomodal";

export const TOGGLE_UPLOAD_LINK_MODAL = "upload_link_modal";

export const TOGGLE_INTEREST_MODAL = "interestmodal";

export const TOGGLE_PASSWORD_MODAL = "passwordmodal";

export const TOGGLE_SEND_NOTIF_MODAL = "sendnotification";

export const TOGGLE_PROFILE_MODAL = "profilemodal";

export const SEARCH_FILTER_MODAL = "filtermodal";

export const POINTS_MODAL = "pointsmodal";

export const STAGE_MODAL = "stagemodal";

export const TEST_LINK_MODAL = "testlinkmodal";

export const VIDEO_PAGE_MODAL = "videopagemodal";

export const PREMIUM_COURSE_ENROLL = "premiumcourseenroll";

// for profile modals
export const ADD_ABOUT_MODAL = "addaboutmodal";

export const ADD_EXPERIENCE_MODAL = "addexperiencemodal";

export const ADD_EDUCATION_MODAL = "addeducationmodal";

export const ADD_SKILL_MODAL = "addskillmodal";

export const ADD_CERTIFICATE_MODAL = "addcertificatemodal";

export const ADD_AWARD_MODAL = "addawardmodal";

export const ADD_PROJECT_MODAL = "addprojectmodal";

export const ADD_CONTACT_MODAL = "addcontactmodal";

export const REQUEST_RECOMMENDATION_MODAL = "requestrecommendationmodal";

export const VIEW_CONTACT = "viewcontact";

export const ALUMNI_MODAL = "alumnimodal";

export const REDEEM_MODAL = "redeemmodal";

export const VIDEO_UPDATE_MODAL = "videoupdatemodal";

export const INTRO_MODAL = "intromodal";

export const RESOURCES_MODAL = "resourceModal";

export const CONFIRM_DETAILS_MODAL = "confirmDetailsModal";

export const POLICY_MODAL = "policymodal";

export const PUBLISH_COURSE_MODAL = "publishcoursemodal";

export const DELETE_STAGE_MODAL = "deletestagemodal";

export const DELETE_CONTENT_MODAL = "deletecontentmodal";

export const REVIEW_MODAL = "reviewmodal";

export const TESTIMONIAL_MODAL = "testimonialmodal";

export const DELETE_USER_MODAL = "deleteusermodal";

export const PROGRESS_BAR_MODAL = "progressbarmodal";

export const VIOLATION_ERROR_MODAL = "violationerrormodal";
