import actions from "@/store/actions";
import utils from "@/utils";
import api from "@/api";

export const fetchUser = () => {
  return async (dispatch) => {
    dispatch(actions.userActions.fetchuser());
    const response = await api.getme();
    if (response?.status >= 400) {
      dispatch(actions.userActions.fetchfailure(response.data));
      utils.setCookies("login_state", false);
      utils.setCookies("verification", false);
    } else {
      dispatch(actions.userActions.fetchsuccess(response.data));
      utils.setCookies("login_state", true);
      utils.setCookies("verification", !response?.data?.isVerified);
      if (response?.data?.isCreator)
        utils.setCookies("creator_state", response.data?.isCreator);
    }
  };
};

export const logoutUser = () => {
  return async (dispatch) => {
    // remove firebase tokentoken from db
    if (utils.getCookie("cs-firebase-token")?.length) {
      const response = await api.deleteToken(
        utils.getCookie("cs-firebase-token")
      );
    }
    utils.deleteCookie("creator_state");
    utils.setCookies("login_state", false);
    utils.removeFromLocalStorage("curr_video");
    utils.removeFromLocalStorage("curr_course");
    utils.removeFromLocalStorage("course_video");
    utils.removeFromLocalStorage("home-tour-guide");
    utils.removeFromLocalStorage("new-feature");
    utils.removeFromLocalStorage("video-tour-guide");
    utils.removeFromLocalStorage("profile-tour-guide");
    utils.removeFromLocalStorage("newItem-tour-guide");
    utils.removeFromLocalStorage("ResumeData");
    utils.removeFromLocalStorage("brave-alert");
    utils.removeFromLocalStorage("site-tour-status");
    await utils.deletebycollection("cs-video-meta-data");
    await utils.deletebycollection("cs-serviceworker");
    await utils.deletebycollection("pdfBlob");
    await utils.deletebycollection("alumni");
    await utils.deletebykey("cs-user", "prefvideo");
    await utils.updatebykey("cs-user", "AuthKey", {
      login_state: false,
    });
    dispatch(fetchUser());
  };
};
