import types from "../types";

export const addcoursedetails = (data) => ({
  type: types.UploadCourse.ADD_COURSE_DETAILS,
  payload: data,
});

export const addcurriculam = (data) => ({
  type: types.UploadCourse.ADD_CURRICULUM,
  payload: data,
});

export const fetchstagedetils = () => ({
  type: types.UploadCourse.FETCH_STAGE,
  payload: data,
});

export const addstage = () => ({
  type: types.UploadCourse.ADD_STAGE,
  payload: data,
});

export const addlecture = () => ({
  type: types.UploadCourse.ADD_LECTURE,
  payload: data,
});
