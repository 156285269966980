import actions from "@/store/actions";
import apis from "@/api";

export const addBundleDetails = (data) => {
  return async (dispatch) => {
    if (!data?.id) {
      dispatch(actions.uploadBundle.addbundledetails(null));
      return;
    } else {
      const res = await apis.getbundledetails(data?.id);
      // console.log({ res });
      if (res?.status === 200) {
        dispatch(actions.uploadBundle.addbundledetails({ ...res.data.result }));
      } else {
        dispatch(actions.uploadBundle.addbundledetails(null));
        // window.location.replace("/upload/bundle");
      }
    }
  };
};
