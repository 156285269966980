import types from "../types";

export const fetchUserDetails = (userDetails) => ({
  type: types.userDetailTypes.FETCH_USER_DETAILS,
  payload: userDetails,
});

export const updateUserProfileProgress = (profileProgress) => ({
  type: types.userDetailTypes.UPDATE_PROFILE_PROGRESS,
  payload: profileProgress,
});

export const resetUserDetails = () => {
  return {
    type: types.userDetailTypes.RESET_USER_DETAILS,
    payload: { reset: true },
  };
};
