import { socketEnrollment, socketUnEnroll } from "@/socket";
import actions from "@/store/actions";
import utils from "@/utils";
import api from "@/api";
import services from ".";

export const fetchEnrollment = (user) => {
  return async (dispatch) => {
    if (utils.getCookie("login_state") || user?.email) {
      const response = await api.myEnrollments();
      if (response.data?.success === 200) {
        dispatch(
          actions.enrollmentActions.fetchenrollment(response.data?.result)
        );
        return;
      }
    }
    dispatch(actions.enrollmentActions.fetchenrollment({}));
  };
};

export const enrollUser = (courseId, userId, socket) => {
  console.log({ courseId, userId });
  return async (dispatch) => {
    try {
      const response = await api.courseEnroll(courseId);
      if (response.data?.success === 200) {
        socketEnrollment(
          {
            userId,
            entityId: courseId,
          },
          socket
        );

        dispatch(fetchEnrollment());
        dispatch(
          services.updateEnrollmentStatus({ isEnrolled: response.data.result })
        );
        dispatch(services.successAlert("You've enrolled into the course"));
      } else {
        dispatch(services.failureAlert("Enrollment failed!"));
      }
    } catch (error) {
      console.error({ error });
    }
  };
};

export const unEnrollUser = (courseId, userId, socket) => {
  return async (dispatch) => {
    try {
      const response = await api.courseUnenroll(courseId);
      if (response.data?.success === 200) {
        socketUnEnroll(
          {
            userId,
            entityId: courseId,
          },
          socket
        );

        dispatch(fetchEnrollment());
        dispatch(services.updateEnrollmentStatus({ isEnrolled: false }));
        dispatch(
          services.successAlert(
            "You've successfully unenrolled from the course!"
          )
        );
      } else {
        dispatch(services.failureAlert("Unenrollment failed!"));
      }
    } catch (error) {
      console.error({ error });
    }
  };
};

// export const addProgress = (courseId, videoId) => {
//   return async (dispatch) => {
//   const response = await api.updateProgress(courseId, videoId);
//   if (response.data.success === 200) {
//     dispatch(fetchEnrollment());
//   }
//   };
// };
