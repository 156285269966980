import * as userTypes from "./user.types";
import * as userDetailTypes from "./userDetails.types";
import * as themeTypes from "./theme.types";
import * as modalTypes from "./modal.types";
import * as videoTypes from "./video.types";
import * as resumeTypes from "./resume.types";
import * as pointsTypes from "./points.types";
import * as uploadTypes from "./upload.types";
import * as networkTypes from "./network.types";
import * as videoBarTypes from "./videobar.types";
import * as locPointsTypes from "./loc_points.types";
import * as enrollmentTypes from "./enrollment.types";
import * as engagementTypes from "./engagement.types";
import * as videoPreference from "./video_preference";
import * as videoMetaDataTypes from "./video_meta_data.types";
import * as UploadCourse from "./course_upload.types";
import * as UploadBundle from "./bundle_upload.types";
import * as entityTypes from "./entity.types";
import * as tourTypes from "./tour.types";

const types = {
  userTypes,
  userDetailTypes,
  themeTypes,
  tourTypes,
  modalTypes,
  videoTypes,
  resumeTypes,
  pointsTypes,
  uploadTypes,
  networkTypes,
  entityTypes,
  UploadCourse,
  UploadBundle,
  videoBarTypes,
  locPointsTypes,
  enrollmentTypes,
  videoPreference,
  engagementTypes,
  videoMetaDataTypes,
};

export default types;
