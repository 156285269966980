import types from "../types";

export const addMetaData = (data) => ({
  type: types.videoMetaDataTypes.ADD_VIDEO_META_DATA,
  payload: data,
});

export const resetMetaData = () => ({
  type: types.videoMetaDataTypes.RESET_VIDEO_META_DATA,
  payload: [],
});

export const addCurrVideo = (data) => ({
  type: types.videoMetaDataTypes.ADD_CURR,
  payload: data,
});

export const updateMetaData = (data) => ({
  type: types.videoMetaDataTypes.UPDATE_VIDEO_META_DATA,
  payload: data,
});

export const addBulkMetaData = (data) => ({
  type: types.videoMetaDataTypes.ADD_BULK_VIDEO_META_DATA,
  payload: data,
});
