import types from "../types";

export const addentity = (entity) => ({
  type: types.entityTypes.ADD_ENTITY,
  payload: entity,
});

export const resetentity = (entity) => ({
  type: types.entityTypes.RESET_ENTITY,
  payload: entity,
});
