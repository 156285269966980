import actions from "@/store/actions";

export const playerInitialize = (data) => {
  return async (dispatch) => {
    dispatch(actions.videoPreferenceActions.playerInitialize(data));
  };
};

export const updateVideoPreference = (data) => {
  return async (dispatch) => {
    dispatch(actions.videoPreferenceActions.updateVideoPreference(data));
  };
};
